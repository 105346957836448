// import { Collapse } from 'bootstrap';
// import { TargetSpy } from '../../scripts/helpers/target-spy';

const header = () => {
  const headerMain = document.querySelector('.js__header-main') as HTMLElement;
  let isSticky: boolean = false;

  if (!headerMain) return;

  const handleScroll = (): void => {
    try {
      const { scrollY } = window;

      if (scrollY > 0) {
        if (isSticky) return;

        isSticky = true;
      }

      if (scrollY === 0) {
        if (!isSticky) return;

        isSticky = false;
      }

      headerMain.classList.toggle('header-main--sticky', isSticky);
    } catch (e) {
      console.warn('E', e);
    }
  };

  const handleSize = (): void => {
    try {
      const headerMainHeight = headerMain.offsetHeight;
      document.body.style.setProperty('--hm-height', `${headerMainHeight}px`);
    } catch (e) {
      console.warn('E', e);
    }
  };

  const handleColor = (): void => {
    try {
      const firstSection = document.querySelector('.section:first-of-type') as HTMLElement;
      if (!firstSection) return;

      const sectionBgColor = getComputedStyle(firstSection).getPropertyValue('--section-bg-color');
      document.body.style.setProperty('--hm-bg-color', sectionBgColor);
    } catch (e) {
      console.warn('handleColor E', e);
    }
  };

  const listeners = (): void => {
    try {
      window.addEventListener('load', () => {
        handleColor();
        handleScroll();
        handleSize();
      });
      window.addEventListener('resize', () => {
        handleScroll();
        handleSize();
      });
      window.addEventListener('scroll', handleScroll);
    } catch (e) {
      console.warn('E:', e);
    }
  };

  listeners();
};

document.addEventListener('DOMContentLoaded', () => {
  header();
});

export default header;
