const jumbotronCta = (): void => {
  const jumbotronCta = document.querySelector('.js__jumbotron-cta') as HTMLElement;

  if (!jumbotronCta) return;

  const handleSize = (): void => {
    try {
      const height = jumbotronCta.offsetHeight;
      document.body.style.setProperty('--jc-height', `${height}px`);
    } catch (e) {
      console.warn('E', e);
    }
  };

  const listeners = (): void => {
    try {
      window.addEventListener('load', () => {
        handleSize();
      });
      window.addEventListener('resize', () => {
        handleSize();
      });
    } catch (e) {
      console.warn('E:', e);
    }
  };

  listeners();
};

document.addEventListener('DOMContentLoaded', () => {
  jumbotronCta();
});

export default jumbotronCta;