type TAPSProps = {
  element: HTMLElement;
  isActive: boolean;
};

const ACTIVE_CLASS = 'active';

const audioPlayer = (): void => {
  // small audio
  try {
    const audioPlayerSmall = document.querySelectorAll('.js__audio-player-wrapper--small');
    if (!audioPlayerSmall.length) return;

    const itemToAPS = (item: HTMLElement): TAPSProps => {
      try {
        const currentItem = {
          element: item as HTMLElement,
          isActive: false
        };

        const toggleItem = (item: TAPSProps, state: boolean): void => {
          try {
            item.element.classList.toggle(ACTIVE_CLASS, state);
            item.isActive = state;

            const audio = item.element.querySelector('.js__audio-player') as HTMLAudioElement;
            if (!audio) return;

            audio.addEventListener('ended', () => toggleItem(item, false));
            state ? audio.play() : (() => {
              audio.pause();
              audio.currentTime = 0;
            })();
          } catch (e) {
            console.warn('toggleItem E', e);
          }
        };

        const clickHandler = (): void => {
          try {
            const prevItem = APSList.find(item => item.isActive);
    
            if (prevItem) {
              toggleItem(prevItem, false);

              if (prevItem.element === item) return;
            }
    
            toggleItem(currentItem, true);
          } catch (e) {
            console.warn('clickHandler E', e);
          }
        };

        const btn = item.querySelector('.js__audio-player-btn');
        btn && btn.addEventListener('click', clickHandler);
          
        return currentItem;
      } catch (e) {
        console.warn('itemToAPS E', e);
        return {
          element: item as HTMLElement,
          isActive: false
        }
      }
    };

    const APSList: TAPSProps[] = Array.from(audioPlayerSmall).map((item) => {
      return itemToAPS(item as HTMLElement);
    });
  } catch (e) {
    console.warn('audioPlayer E', e);
  }
};

document.addEventListener('DOMContentLoaded', () => {
  audioPlayer();
});

export default audioPlayer;
