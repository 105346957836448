import Swiper, { Navigation, Pagination, SwiperOptions } from 'swiper';
Swiper.use([Navigation, Pagination]);

const carousel = (wrapper: HTMLElement): void => {
  try {
    const carousel = wrapper.querySelector('.js__carousel') as HTMLElement;
    const { dataset } = carousel;
    const { slidesPerView, spaceBetween } = dataset;

    const props: SwiperOptions = {
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: slidesPerView && Number.parseInt(slidesPerView) || 3
        }
      },
      containerModifierClass: 'carousel-',
      slideClass: 'carousel-slide',
      slideActiveClass: 'carousel-slide--active',
      slideNextClass: 'carousel-slide--next',
      slidePrevClass: 'carousel-slide--prev',
      slidesPerView: 1,
      spaceBetween: spaceBetween && Number.parseInt(spaceBetween) || 15,
      pagination: {
        el: wrapper.querySelector('.carousel-pagination') as HTMLElement,
        bulletClass: 'carousel-pagination-bullet',
        bulletActiveClass: 'carousel-pagination-bullet--active',
        modifierClass: 'carousel-pagination--',
        clickableClass: 'carousel-pagination--clickable',
        clickable: true,
        lockClass: 'carousel-pagination--lock',
      },
      navigation: {
        nextEl: wrapper.querySelector('.carousel-btn--next') as HTMLElement,
        prevEl: wrapper.querySelector('.carousel-btn--prev') as HTMLElement,
        disabledClass: 'carousel-btn--disabled',
        lockClass: 'carousel-btn--lock',
      },
      wrapperClass: 'carousel-slides'
    }

    new Swiper(carousel, props);
  } catch (e) {
    console.warn('carousel E', e);
  }
};

document.addEventListener('DOMContentLoaded', () => {
  const carousels = document.querySelectorAll('.js__carousel-container') as NodeListOf<HTMLElement>;

  Array.from(carousels).map(item => carousel(item));
});

export default carousel;